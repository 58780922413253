import React from "react";
import Loader from "@components/loader";
import { Suspense, lazy } from "@uploadcare/client-suspense";

const HomePage = lazy(() => import("../pages/home"));
function IndexPage() {
  return (
    <Suspense fallback={<Loader />}>
      <HomePage />
    </Suspense>
  );
}

export default IndexPage;
