import React from "react";
import { styled } from "@mui/system";

const LoaderContainer = styled("div")(() => ({
    width: "100vw",
    height: "100vh",
}));

function Loader() {
  return (
    <LoaderContainer>
    </LoaderContainer>
  );
}

export default Loader;
